<template>
	<!--content star -->
	<div class="content modifyx">
		<h2 class="modifyH2">修改账户信息</h2>
		<div class="operate-box">
			<p>原账户名：{{name}}</p>
			<el-input
			  placeholder="邮箱/手机/用户名（可填写中文）"
			  v-model="userName"
			  clearable
			  minlength=3
			  maxlength=19>
			</el-input>
			<p>原密码：{{passWord}}</p>
			<el-input
			  placeholder="密码"
			  v-model="paw"
			  clearable
			  minlength=3
			  maxlength=19>
			</el-input>
			<div>
				<el-button type="danger" style="font-weight: 700;" size="small" plain @click="modifyAxios">修改</el-button>
				<el-button type="danger" @click="toMallInfo" size="small" plain>取消</el-button>
			</div>
		</div>
		<Bottom></Bottom>
		 <el-dialog
		  title="温馨提示"
		  :visible.sync="centerDialogVisible2"
		  width="80%"
		  top="20vh"
		  center>
		  <div class="dialogBodyx">
			  <p style="font-size: 18px;color: green;font-weight: 700;text-align: center;">
				  <i class="el-icon-success"></i>充值成功
			  </p><br/>
			  <p style="font-size: 16px;">
				  请先修改账号密码或者保存您的账号,再进行观看!<!-- <br/>购买单部视频的请到已购买里面查看 -->
			  </p>
		  </div>
		  <span slot="footer" class="dialog-footer"></span>
		</el-dialog>
		<el-dialog
		  title="请截图保存!"
		  :visible.sync="centerDialogVisible3"
		  width="85%"
		  top="15vh"
		  :show-close=showClosex
		  :close-on-click-modal=showClosex
		  :close-on-press-escape=showClosex
		  center>
		  <div class="dialogBodyx">
			  <div class="jtbcUser">
				  <p>账户：<span>{{name}}</span></p>
				  <p>密码：<span>{{passWord}}</span></p>
				  <p><span>因切换网络有可能会生成新的账户，请记住自己充值过的账户密码，如果不能看了请切换回充值过的账户！</span></p>
				  <!-- <p v-for="(item, index) in GLOBAL.domainData" v-if="index<3" :key="index">
					   备用地址{{index+1}}：<a href="javascript:;">{{item.address}}</a>
				  </p> -->
				  <!-- <img src="../../public/img/tan11.png" /> -->
				  <QRCodeGenerator :itemWidth="110" :itemHeight="110" />
				  <p>使用手机浏览器或其他浏览器扫描二维码，即可跳转到最新域名！</p>
				  <p>联系邮箱：<a href="javascript:;">91tantan@gmail.com</a>(发送任意内容，获取最新网址)</p>
			  </div>
		  </div>
		  <span slot="footer" class="dialog-footer">
		      <el-button type="primary" @click="dialogVisible = true">我已保存</el-button>
		    </span>
		</el-dialog>
		<el-dialog
		  title="提示"
		  :visible.sync="dialogVisible"
		  width="80%"
		  top="30vh"
		  center>
		  <div style="text-align: center;">
			  <span>是否已截图保存？</span>
		  </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button @click="dialogVisible = false">否 </el-button>
		    <el-button type="primary" @click="toMallInfo()">是 </el-button>
		  </span>
		</el-dialog>
	</div>
	<!--content end -->
</template>

<script>
	import Bottom from '../components/bottom.vue';
	import QRCodeGenerator from '../components/QRCodeGenerator.vue';
	export default{
		components:{
			Bottom,
			QRCodeGenerator
		},
		data() {
		  return {
			  userName:'',
			  paw:'',
			  userId:"",
			  name:"",
			  passWord:"",
			  centerDialogVisible2:false,
			  showClosex:false,
			  centerDialogVisible3:false,
			  dialogVisible:false,
		  }
		},
		created(){
			this.userId = this.$parent.userId==undefined?this.$route.query.userId:this.$parent.userId;
			if(this.userId==null || this.userId==""){
				if(this.$parent.userId!=null && this.$parent.userId!=undefined && this.$parent.userId!=""){
					this.userId = this.$parent.userId;
				}else if(this.$route.query.userId!=null && this.$route.query.userId!=undefined && this.$route.query.userId!=""){
					this.userId = this.$route.query.userId;
				}
			}
			 if(this.$route.query.typePath=="my" || this.$route.query.typePath=="video"){
				this.centerDialogVisible2 = false;
			}else{
				this.centerDialogVisible2 = true;
			}
			/* if(this.GLOBAL.domainData=="" || this.GLOBAL.domainData==null){
				this.domainAxios();
			} */
			this.loginAxios();
		},
		methods: {
			toMallInfo(){
				this.dialogVisible = false;
				this.centerDialogVisible3 = false;
				 this.$router.push({
					name: "My",
					query: {
						userId:this.userId
					}
				});
			},
			toHome(){
				this.$router.push({
					name: "Index",
					query: {
						userId:this.userId
					}
				});
			},
			loginAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('id', that.userId);
				params.append('type', "1");
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/login',params)
				  .then(function (response) {
					  var datas = response.data.data;
					  if(response.data.code==1){
						 that.name = datas.name;
						 that.passWord = datas.password;
					  }
				  })
				  .catch(function (error) {
					console.log(error);
				  });
			},
			modifyAxios(){
				var that = this;
				this.userName = this.userName.replace(/\s*/g,"");
				this.paw = this.paw.replace(/\s*/g,"");
				if(this.userName.length<=0){
					this.open("通知","账户不能为空");
					return;
				}
				if(this.paw.length<=0){
					this.open("通知","密码不能为空");
					return;
				}
				const loading = this.$loading({
				  lock: true,
				  text: '正在修改中...',
				  spinner: 'el-icon-loading',
				  background: 'rgba(0, 0, 0, 0.7)'
				});
				var params = new URLSearchParams();
				params.append('id', this.userId);
				params.append('name', this.userName);
				params.append('password', this.paw);
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/user/modify',params)
				  .then(function (response) {
					  loading.close();
					  var datas = response.data.data;
					  if(response.data.code==1){
						 that.centerDialogVisible3 = true;
						 that.userId = datas.id;
						 that.name = datas.name;
						 that.passWord = datas.password;
						 localStorage.setItem("tantan_name",datas.name);
						 localStorage.setItem("tantan_userId_ds",datas.id);
						 //that.toHome();
					  }else{
						  that.open('通知',response.data.message);
					  }
				  })
				  .catch(function (error) {
					  loading.close();
					  console.log(error);
				  });
			},
			open(title,content) {
				this.$notify({
				  title: title,
				  message: content,
				  offset: 100
				});
			},
			domainAxios(){
				var that = this;
				var params = new URLSearchParams();
				params.append('version', '3');
				params.append('status', '2');
				params.append('type', '2');
				this.axios.post(this.GLOBAL.serverSrc+'mobile/tantan/zhuanfa/listLuodi',params)
				  .then(function (response) {
					  var datas = response.data.data;
					  if(response.data.code==1){
						var numx = datas.length;
						if(numx>0){
							that.GLOBAL.domainData = datas;
						}
					}
				  })
				  .catch(function (error) {
					  console.log(error);
				  });
			},
		}
	}
</script>

<style>
	.modifyH2{
	   text-align: center;
	   color: #7a7a7a;
	}
	.operate-box{
	   max-width: 940px;
	   margin: 0 auto;
	   background: white;
	   padding: 25px 15px;
	   border-radius: 10px;
	   margin-bottom: 20px;
	}
	.operate-box p{
	   margin-top: 5px;
	   padding-bottom: 5px;
	}
	.operate-box div{
	   margin-top: 20px;
	   text-align: center;
	}
	.jtbcUser p{
		line-height: 30px;
		font-weight: 700;
	}
	.jtbcUser p span{
		color: red;
	}
	.dialogBodyx{
		padding: 10px 15px;
	}
	.jtbcUser img{
		width: 110px;
	    height: 110px;
	}
</style>
