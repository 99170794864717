<template>
	<div class="content homeDiv">
		<div class="home18div">18+</div>
		<div>
			<p>您正在訪問18+成人網站，若未滿18歲，請離開此頁面</p>
			<p class="ydts">【重要通知】：移動網絡頻繁屏蔽本站,請更換（電信或聯通）網絡访问 ！</p>
			<div @click="toIndex">
				<el-button style="width: 70%;height: 60px;font-size: 16px;" type="primary"><u>同意，點擊進入最新網站</u></el-button>
			</div>
		</div>
		<div class="homeDiv2">
			<p>1：請记住本站最新網址（添加到收藏） <span style="color: red;">{{homeHost}}</span></p>
			<p>2：【 本站永久在线觀看,因常換域名,請收藏【10】個域名以上! 】</p>
			<p>3：郵箱聯系方式: <span style="color: red;">91tantan@gmail.com</span> （發送任意內容獲取最新地址）</p>
			<p>4：推薦使用谷歌浏覽器訪問</p>
		</div>
	</div>
</template>

<script>
	export default{
		components:{
			
		},
		data() {
		  return {
			homeHost:"",
		  }
		},
		created() {
			this.homeHost = "https://"+document.domain;
	    },
		mounted() {
		    // 在组件挂载后开始计时
		    this.startTimer();
		},
		methods: {
			startTimer() {
			  // 设置一个30秒的定时器
			  setTimeout(() => {
				// 30秒后执行跳转操作
				this.toIndex();
			  }, 30000); // 30秒，单位为毫秒
			},
			toIndex(){
			 this.$router.push({
				name: "Index",
			 });
			},
		}
	}
</script>

<style>
	.homeDiv{
		text-align: center;
	}
	.home18div{
		color: #F06F0F;
		font-size: 2.2rem;
		border: 1px solid #F06F0F;
		border-radius: 50%;
		display: inline-block;
		width: 70px;
		height: 70px;
		line-height: 70px;
		margin-bottom: 25px;
	}
	.homeDiv .ydts{
		color: red;
	}
	.homeDiv p{
		font-size: 0.875rem;
		margin-bottom: 1.25rem;
	}
	.homeDiv2{
		margin-top: 3rem;
	}
</style>
